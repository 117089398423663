import React, { useState, useContext } from 'react';
import AdminContext from '../context/adminContext';
import spinner from '../assets/images/spinner.svg';

const AddPhoto = props => {
  const context = useContext(AdminContext);
  const [caption, setCaption] = useState('');
  const [order, setOrder] = useState(1);
  const [photo, setPhoto] = useState('');
  const [searchTags, setSearchTags] = useState('');
  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFile = e => {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handlePhotoUpload = async e => {
    e.preventDefault();
    setLoading(true);
    let photoOrder = order > props.gallery.photos.length ? props.gallery.photos.length + 1 : order;
    const upload = {
      photo,
      order: photoOrder,
      caption,
      searchTags,
    };

    await context
      .uploadPhoto(upload, props.gallery.name, props.gallery.category, props.gallery._id)
      .then(() => {
        setLoading(false);
        setCaption('');
        setOrder(1);
        setPhoto('');
        setPreview('');
        setSearchTags('');
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <AdminContext.Consumer>
      {context => (
        <div className="addPhotoWrapper grid-image" style={{ position: 'relative', width: '100%' }}>
          <div className="spinner-overlay" style={{ display: context.addPhoto && loading ? 'block' : 'none' }}>
            <div className="spinner-wrapper">
              <img className="spinner" src={spinner}></img>
            </div>
          </div>
          <figure
            className={context.addPhoto == false ? 'hover-cursor add-photo' : 'add-photo'}
            role="button"
            onClick={context.addPhoto == false ? () => context.toState({ addPhoto: true, editPhoto: false }) : null}
          >
            {/* {this.props.addPhoto == false ? <h3>Add a New Photo +</h3> : null} */}
            <h3>Add a New Photo +</h3>
            {context.addPhoto == true ? (
              <form>
                <input type="text" placeholder="caption" name="caption" value={caption} onChange={e => setCaption(e.target.value)}></input>
                <input type="number" placeholder="order" name="order" value={order} onChange={e => setOrder(e.target.value)}></input>

                <input id="imageUpload" type="file" name="photoUpload" accept="image/png,image/jpeg" onChange={e => handleFile(e)} />
                <div style={{ width: '300px' }}>{preview ? <img src={preview} alt="preview" style={{ width: '50%', height: 'auto' }} /> : null}</div>
                <input
                  type="text"
                  placeholder="search tags"
                  name="search tags"
                  value={searchTags}
                  onChange={e => setSearchTags(e.target.value)}
                ></input>
                <div className="cancel-submit">
                  <input type="button" name="cancel" className="cancel-button" value="Cancel" onClick={() => context.toState({ addPhoto: false })} />
                  <input type="submit" name="submitPhoto" className="submit-button" value="Submit Photo" onClick={e => handlePhotoUpload(e)} />
                </div>
              </form>
            ) : null}
          </figure>
        </div>
      )}
    </AdminContext.Consumer>
  );
};

export default AddPhoto;
